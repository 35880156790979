import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Row } from '../../../../Common/Row/Row';
import { NewRT } from '../../../../Common/RichText/NewRT';
import { Alert } from '../../../../Alert/Alert';
import { EAlertType, EAlertTypeSchema } from '../../../../../entities/IAlert';

const Wrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem;
    }
    > :last-child {
        margin: 0;
    }
`;

export const Step6: FC<IMainStepProps> = props => {
    const intl = useIntl();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);

    useEffect(() => {
        eApprovalContext.modifyData(intl.formatMessage({id: 'eApproval.wizard.step6.eapproval-free-declaration.message'}), '', props.context, props.stepName, props.stepNumber);
    }, []);

    const onContextChange = (value: string) => {
        if (value !== eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.message) {
            const span = document.createElement('span');
            span.innerHTML = value;
            if (span.innerText?.trim()?.length > 0) {
                eApprovalContext.modifyData(value, '', props.context, props.stepName, props.stepNumber);
            } else if (span.innerText?.trim()?.length === 0) {
                eApprovalContext.modifyData('', '', props.context, props.stepName, props.stepNumber);
            }
        }
    };

    return (
        <Wrapper>
            <Row numberOfElements={1} noBorder>
                <Alert
                    transparentRight
                    type={EAlertType.ERROR}
                    typeSchema={EAlertTypeSchema.SECONDARY}
                    withoutClose
                    withoutProgress
                    visible
                    alertId="1"
                >
                    <FormattedMessage id={`eApproval.wizard.step6.${[EApprovalTypes.FreeDeclaration]}.warning`} />
                </Alert>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <NewRT
                        label={intl.formatMessage({ id: `eApproval.wizard.step6.${[EApprovalTypes.FreeDeclaration]}.context` })}
                        required
                        content={eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.message || intl.formatMessage({id: 'eApproval.wizard.step6.eapproval-free-declaration.message'})}
                        onChange={onContextChange}
                    />
                </div>
            </Row>
        </Wrapper>
    );
};