import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from '../reducers';
import { IConfigReducer } from '../reducers/configReducer';
import { IContextReducer } from '../reducers/contextReducer';
import { ISingleConversation, ISingleMessage } from '../entities/Messaging/IMessaging';
import { createSignlaRHubConnection } from '../actions/messagingActions';
import { IClusterSingleMessage, IClusterValidationRequestStatusUpdateMessage, ISingleCluster } from '../entities/IClusters';

export const useNotification = () => {
    const dispatch = useDispatch();
    const { token, appConfig } = useSelector<IState, IContextReducer & IConfigReducer>(state => ({
        ...state.context,
        ...state.config
    }));
    const [connection, setConnection] = useState(undefined);
    const [receiveChannelMessage, setReceiveChannelMessage] = useState<IClusterSingleMessage>(undefined);
    const [updateChannelMessage, setUpdateChannelMessage] = useState<IClusterSingleMessage>(undefined);
    const [assignedRoleCreated, setAssignedRoleCreated] = useState<IClusterSingleMessage>(undefined);
    const [deleteChannelMessage, setDeleteChannelMessage] = useState<IClusterSingleMessage>(undefined);
    const [channelMembersAdded, setChannelMembersAdded] = useState<any>(undefined);
    const [channelMemberRemoved, setChannelMemberRemoved] = useState<any>(undefined);
    const [messageWasRead, setMessageWasRead] = useState<any>(undefined);
    const [receiveNotification, setReceiveNotification] = useState<any>(undefined);
    const [conversationMemberRemoved, setConversationMemberRemoved] = useState<any>(undefined);
    const [assignToClusterCompleted, setAssignToClusterCompleted] = useState<ISingleCluster>(undefined);
    const [receiveConversation, setReceiveConversation] = useState<ISingleConversation>(undefined);
    const [attachmentUploaded, setAttachmentUploaded] = useState<ISingleMessage>(undefined);
    const [receiveMessage, setReceiveMessage] = useState<ISingleMessage>(undefined);
    const [clusterValidationRequestStatusUpdateMessageDto, setClusterValidationRequestStatusUpdateMessageDto] = useState<IClusterValidationRequestStatusUpdateMessage>(undefined);

    useEffect(() => {
        let newConnection = undefined;
        if (token && appConfig.signalRUri) {
            newConnection = dispatch(createSignlaRHubConnection(appConfig.signalRUri, token));
            setConnection(newConnection);
        }
        return () => {
            if (newConnection) {
                newConnection.off('receiveMessage');
                newConnection.off('AssignedRoleCreated');
                newConnection.off('receiveConversation');
                newConnection.off('messageWasRead');
                newConnection.off('attachmentUploaded');
                newConnection.off('receiveNotification');
                newConnection.off('conversationMemberRemoved');
                newConnection.off('ReceiveChannelMessage');
                newConnection.off('UpdateChannelMessage');
                newConnection.off('ChannelMembersAdded');
                newConnection.off('ChannelMemberRemoved');
                newConnection.off('SystemDocumentsUploaded');
                newConnection.off('ClusterValidationRequestStatusUpdateMessageDto');
            }
        };
    }, [appConfig, token]);

    useEffect(() => {
        if (connection) {
            connection.start().then(result => {
                connection.on('ReceiveChannelMessage', (message: IClusterSingleMessage) => {
                    console.log('ReceiveChannelMessage', message);
                    setReceiveChannelMessage(message);
                });
                connection.on('UpdateChannelMessage', (message: IClusterSingleMessage) => {
                    console.log('UpdateChannelMessage', message);
                    setUpdateChannelMessage(message);
                });
                connection.on('AssignedRoleCreated', (message: IClusterSingleMessage) => {
                    console.log('AssignedRoleCreated', message);
                    setAssignedRoleCreated(message);
                });
                connection.on('ChannelMembersAdded', (message) => {
                    console.log('ChannelMembersAdded', message);
                    setChannelMembersAdded(message);
                });
                connection.on('ChannelMemberRemoved', (message) => {
                    console.log('ChannelMemberRemoved', message);
                    setChannelMemberRemoved(message);
                });
                connection.on('AssignToClusterCompleted', (message: ISingleCluster) => {
                    console.log('AssignToClusterCompleted', message);
                    setAssignToClusterCompleted(message);
                });
                connection.on('DeleteChannelMessage', (message: IClusterSingleMessage) => {
                    console.log('DeleteChannelMessage', message);
                    setDeleteChannelMessage(message);
                });
                connection.on('messageWasRead', (message) => {
                    console.log('messageWasRead', message);
                    setMessageWasRead(message);
                });
                connection.on('receiveConversation', (message: ISingleConversation) => {
                    console.log('receiveConversation', message);
                    setReceiveConversation(message);
                });
                connection.on('attachmentUploaded', (message: ISingleMessage) => {
                    console.log('attachmentUploaded', message);
                    setAttachmentUploaded(message);
                });
                connection.on('receiveMessage', (message: ISingleMessage) => {
                    console.log('receiveMessage', message);
                    setReceiveMessage(message);
                });
                connection.on('receiveNotification', (message) => {
                    console.log('receiveNotification', message);
                    setReceiveNotification(message);
                });
                connection.on('conversationMemberRemoved', (message) => {
                    console.log('conversationMemberRemoved', message);
                    setConversationMemberRemoved(message);
                });
                connection.on('ClusterValidationRequestStatusUpdateMessageDto', (message: IClusterValidationRequestStatusUpdateMessage) => {
                    console.log('ClusterValidationRequestStatusUpdateMessageDto', message);
                    setClusterValidationRequestStatusUpdateMessageDto(message);
                });
            });
        }
    }, [connection]);

    return {
        receiveChannelMessage,
        updateChannelMessage,
        assignedRoleCreated,
        channelMembersAdded,
        channelMemberRemoved,
        assignToClusterCompleted,
        deleteChannelMessage,
        messageWasRead,
        receiveConversation,
        attachmentUploaded,
        receiveMessage,
        receiveNotification,
        conversationMemberRemoved,
        clusterValidationRequestStatusUpdateMessageDto
    };
};