import React, { useEffect, useState, useCallback, FC } from 'react';
import { last } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { EAlertType } from '../../../../../entities/IAlert';
import { uploadAttachment, uploadSessionAttachment } from '../../../../../actions/stakeActions';
import { IState } from '../../../../../reducers';
import { IClusterReducer } from '../../../../../reducers/clustersReducer';
import { useAlert } from '../../../../../tools/hooks';
import { checkAttachmentSize, checkAttachmentType } from '../../../../../tools/attachment';
import { uuidv4 } from '../../../../../tools/authTools';
import { EUploadStatus } from './SingleAttachment';
import { Dropzone } from '../../../../NewDesignCommon/Dropzone/Dropzone';
import { IDirectoryContent } from '../../../../../entities/IClustersFilesystem';
import { moveFileBeetwenClusters } from '../../../../../actions/clustersFilesystemActions';
import { EFilesystemItemType } from '../../../../../entities/IClustersFilesystem';
import { Attachment } from '../../../../../components/Common/Attachment/Attachment';

type UploadAttachment = ReturnType<typeof uploadAttachment>;
type UploadSessionAttachment = ReturnType<typeof uploadSessionAttachment>;
type MoveFileBeetwenClusters = ReturnType<typeof moveFileBeetwenClusters>;

interface IAttachmentContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    stakeDefinitionId: string;
    multipleAttachment?: boolean;
    propertieId: string;
    error: string;
    setEditingData(propertie: IClusterStakeProperty);
}

interface IAttachmentContentState {
    [guid: string]: {
        name: string;
        progress: number;
        status: EUploadStatus;
        directUri?: string;
        fileName?: string,
        contentType?: string,
        fileId?: string
    }
}

export const AttachmentContent: FC<React.PropsWithChildren<IAttachmentContentProps>> = ({ multipleAttachment, error, stakeDefinitionId, data, editStake, propertieId, setEditingData }) => {
    const addAlert = useAlert();
    const dispatch = useDispatch();
    const [state, setState] = useState<IAttachmentContentState>(undefined);
    const [loadingFile, setLoadingFile] = useState<boolean>(false);

    const { currentClusterId } = useSelector<IState, IClusterReducer>(store => store.clusters);

    useEffect(() => {
        if ((!editStake && !!data.value) || (editStake && !!data.value && !state)) {
            if (multipleAttachment) {
                setState((data.value || [])
                    .reduce((assignedObject, attachment) => ({
                        ...assignedObject,
                        [uuidv4()]: {
                            ...attachment,
                            status: EUploadStatus.Success,
                            progress: 1
                        }
                    }), {}));
            } else {
                setState({
                    [uuidv4()]: {
                        ...data.value,
                        status: EUploadStatus.Success,
                        progress: 1
                    }
                });
            }
        }
    }, [data, editStake]);

    const handleProgress = useCallback((e: ProgressEvent, itemGuid: string, fileName: string) => {
        setState(currentState => ({
            ...currentState,
            [itemGuid]: {
                name: fileName,
                progress: e.lengthComputable && e.loaded / e.total,
                status: EUploadStatus.Pending
            }
        }));
    }, [state]);

    const fileInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        setLoadingFile(true);
        const localAttachments = Object.keys(state || {}).map(key => ({
            name: state[key].name,
            directUri: state[key].directUri,
            fileName: state[key].name,
            contentType: state[key].contentType,
            fileId: state[key].fileId
        }));
        for (let i = 0; i < files.length; i++) {
            const attachSize = checkAttachmentSize(files[i].size, 'cle');
            const attachType = checkAttachmentType(last(files[i].name.split('.')).toLowerCase(), 'cle');
            const itemGuid = uuidv4();
            const fileName = files[i]?.name;
            setState(currentState => ({
                ...(multipleAttachment ? currentState : {}),
                [itemGuid]: {
                    name: fileName,
                    progress: 0,
                    status: EUploadStatus.Pending
                }
            }));
            if (attachSize.isValid && attachType.isValid) {
                const formData = new FormData();
                formData.append('attachment', files[i]);
                formData.append('propertyId', propertieId);
                formData.append('stakeDefinitionId', stakeDefinitionId);
                if (!!currentClusterId) {
                    dispatch<UploadAttachment>(uploadAttachment(currentClusterId, formData, (event) => handleProgress(event, itemGuid, fileName)))
                        .then(response => {
                            const newAttachment = {
                                name: response.name,
                                directUri: response.directUri,
                                fileName: response.name,
                                contentType: response.contentType,
                                fileId: response.fileId
                            };
                            localAttachments.push(newAttachment);
                            setEditingData({
                                ...data,
                                value: multipleAttachment ? [...(localAttachments || [])] : newAttachment,
                                id: propertieId
                            });
                            setState(currentState => ({
                                ...(multipleAttachment ? currentState : {}),
                                [itemGuid]: {
                                    ...newAttachment,
                                    progress: 1,
                                    status: EUploadStatus.Success
                                }
                            }));
                            setLoadingFile(false);
                        }).catch(() => {
                            setState(currentState => ({
                                ...currentState,
                                [itemGuid]: {
                                    name: fileName,
                                    progress: 1,
                                    status: EUploadStatus.Error
                                }
                            }));
                            setLoadingFile(false);
                        });
                } else {
                    const sessionId = uuidv4();
                    dispatch<UploadSessionAttachment>(uploadSessionAttachment(sessionId, formData))
                        .then(response => {
                            const newAttachment = {
                                name: response.name,
                                directUri: response.directUri,
                                fileName: response.name,
                                contentType: response.contentType,
                                fileId: response.fileId
                            };
                            localAttachments.push(newAttachment);
                            setEditingData({
                                ...data,
                                value: multipleAttachment ? [...(localAttachments || [])] : newAttachment,
                                id: propertieId
                            });
                            setState(currentState => ({
                                ...(multipleAttachment ? currentState : {}),
                                [itemGuid]: {
                                    ...newAttachment,
                                    progress: 1,
                                    status: EUploadStatus.Success
                                }
                            }));
                            setLoadingFile(false);
                        }).catch(() => {
                            setState(currentState => ({
                                ...currentState,
                                [itemGuid]: {
                                    name: fileName,
                                    progress: 1,
                                    status: EUploadStatus.Error
                                }
                            }));
                            setLoadingFile(false);
                        });
                }
            } else {
                addAlert(<span>{attachType.message || attachSize.message}</span>, EAlertType.ERROR);
                setState(currentState => ({
                    ...currentState,
                    [itemGuid]: {
                        name: fileName,
                        progress: 1,
                        status: EUploadStatus.Error
                    }
                }));
                setLoadingFile(false);
            }
        }
    }, [propertieId, data, setEditingData, handleProgress, state]);

    const onAttachmentDelete = useCallback((guid: string) => {
        const { [guid]: removedItem, ...rest } = state;
        setState(rest);
        setEditingData({
            ...data,
            value: multipleAttachment ? Object.keys(rest || {}).map(key => ({
                name: rest[key].name,
                directUri: rest[key].directUri,
                fileName: rest[key].name,
                contentType: rest[key].contentType,
                fileId: rest[key].fileId
            })) : {
                name: undefined,
                directUri: undefined,
                fileName: undefined,
                contentType: undefined,
                fileId: undefined
            },
            id: propertieId
        });
    }, [state, setEditingData, propertieId]);

    const importFile = useCallback((file: IDirectoryContent, closeImportPopup: any, path: string[]) => {
        return new Promise((resolve) => {
            const localAttachments = Object.keys(state || {}).map(key => ({
                name: state[key].name,
                directUri: state[key].directUri,
                fileName: state[key].fileName,
                contentType: state[key].contentType,
                fileId: state[key].fileId
            }));
            const itemGuid = uuidv4();
            dispatch<MoveFileBeetwenClusters>(moveFileBeetwenClusters(currentClusterId, { sourceClusterId: currentClusterId, items: [{ item: file.name, finalItemName: file.name, type: EFilesystemItemType.File }], path: 'Overview Files', sourcePath: path.join('\\') })).then((res) => {
                const newAttachment = {
                    name: file?.name,
                    directUri: file?.directUri,
                    fileName: file?.name,
                    contentType: file?.contentType,
                    fileId: file?.fileId
                };
                localAttachments.push(newAttachment);
                setEditingData({
                    ...data,
                    value: multipleAttachment ? [...(localAttachments || [])] : newAttachment,
                    id: propertieId
                });
                setState(currentState => ({
                    ...(multipleAttachment ? currentState : {}),
                    [itemGuid]: {
                        ...newAttachment,
                        progress: 1,
                        status: EUploadStatus.Success
                    }
                }));
                setLoadingFile(false);
                closeImportPopup();
                resolve(true);
            }).catch(() => {
                resolve(false);
            });
        });
    }, [propertieId, data, setEditingData, state]);

    return (
        <div>
            {editStake ? (
                <Dropzone
                    importFile={importFile}
                    onChange={fileInputChange}
                    loading={loadingFile}
                    fileList={(Object.keys(state || {}) || []).map(attachmentGuid => ({ name: state[attachmentGuid]?.name, directUri: state[attachmentGuid]?.directUri, id: state[attachmentGuid]?.fileId }))}
                    clusterId={currentClusterId}
                    canRemove
                    removeHandler={onAttachmentDelete}
                />
            ) : (
                <>
                    {(Object.keys(state || {}) || []).map(elem =>
                        <Attachment
                            fileName={state[elem]?.name}
                            visibleButtons
                            overviewPath
                            clusterId={currentClusterId}
                            id={state[elem]?.fileId}
                        />
                    )}
                </>
            )}
        </div>
    );
};
